import {
  Component,
  OnInit,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2
} from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-animate',
  templateUrl: './animate.component.html',
  styleUrls: ['./animate.component.css'],
})
export class AnimateComponent implements OnInit, OnChanges {
  MAX_DOC_COUNT = 999999;
  @Input() documentCount!: number;
  @Input() type!: number;
  public ourDocCount = 0;
  private timeline = gsap.timeline({ paused: true });
  public sliderValue = 0;
  public counterValue = 0;
  public totalMaxCount = 0;
  isReadOnly = false;
  valuePercent: number = 0;
  inputText: String = '';
  xValue = 0;
  yValue = 0;
  ngOnDestroy: any;
  docCountString: string = "0"
  constructor(private zone: NgZone, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    // console.log('documentCount: ', this.documentCount);
    const layers = document.querySelectorAll('.layer');
    // this.sliderValue = this.documentCount;
    this.ourDocCount = this.documentCount;
    const animationDuration = 0.5;
    const delayBetweenImages = 0.1;
    const fadeInDuration = 0.5;
    const easing = 'power1.in';
    this.docCountString = String(this.documentCount);

    layers.forEach((layer, index) => {
      this.timeline.fromTo(
        layer,
        { opacity: 0, x: 0 },
        { opacity: 1, duration: fadeInDuration, ease: easing },
        index * (animationDuration + delayBetweenImages)
      );
    });

    gsap.to(this, {
      duration: 2.5, 
      counterValue: this.documentCount,
      onUpdate: () => {
        // Update the counter property
        this.zone.run(() => {
          this.documentCount = Math.round(this.counterValue);
        });
      },
      x: -100,
      ease: "power2.out"
    });
    
    const progress = this.documentCount / this.MAX_DOC_COUNT;
    this.timeline.progress(progress);
    if (this.type == 1) {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
    }

    // const parallaxElements = this.el.nativeElement.querySelectorAll('.layer');

    // const handleMouseMove = (e: MouseEvent) => {
    //   const xValue = e.clientX - window.innerWidth / 2;
    //   const yValue = e.clientY - window.innerHeight / 2;

    //   console.log(xValue, yValue);

    //   parallaxElements.forEach((el: HTMLElement) => {
    //     const speedx = parseFloat(el.dataset.speedx || '1');
    //     // el.style.transform = `translate(calc(-50% + ${-xValue * speedx}px)) translateY(calc(-50% + ${yValue}px))`;
    //     el.style.transform = `translateX(calc(-0% + ${-xValue * speedx}px))`;

    //     // el.style.transform = `translate(${xValue * speedx}px) translateY(${yValue}px)`;
    //   });
    // };

    // window.addEventListener('mousemove', handleMouseMove);

    // // Clean up the event listener when the component is destroyed
    // this.zone.runOutsideAngular(() => {
    //   const ngOnDestroyOriginal = this.ngOnDestroy.bind(this);
    //   this.ngOnDestroy = () => {
    //     window.removeEventListener('mousemove', handleMouseMove);
    //     ngOnDestroyOriginal();
    //   };
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && changes.type.currentValue === 0) {
      const progress = this.ourDocCount / this.MAX_DOC_COUNT;
      this.timeline.progress(progress);
    } else if (changes.type && changes.type.currentValue === 1) {
      const progress = this.documentCount / this.MAX_DOC_COUNT;
      this.timeline.progress(progress);
    }
  }

  handleSliderChange(event: Event, adjust= false) {
    if (adjust) {
      this.adjustWidth(event);
    }
    const value = (event.target as HTMLInputElement).value;
    this.documentCount = value ? +value.replace(/,/g, '') : 0;
    this.docCountString=Number(value).toLocaleString();

    const progress = this.documentCount / this.MAX_DOC_COUNT;
    this.timeline.progress(progress);

    if (this.timeline.isActive()) {
      this.timeline.pause();
    }

    const mySlider = event.target as HTMLInputElement;
    this.valuePercent = (mySlider.valueAsNumber / this.MAX_DOC_COUNT) * 100;
    mySlider.style.background = `linear-gradient(to right, #ccff00 ${this.valuePercent}%, #EAECF0 ${this.valuePercent}%)`;
  }

  getLinearGradient() {
    let valuePercent = (this.documentCount / this.MAX_DOC_COUNT) * 100;
    return `linear-gradient(to right, #ccff00 ${valuePercent}%, #EAECF0 ${valuePercent}%)`;
  }

  adjustWidth(event: Event): void {
    const input = event.target as HTMLInputElement;
    const valueLength = input.value.length;
    const minWidth = 174;
    const baseWidth = 21;
    const maxCharacters = 6;

    if (valueLength < maxCharacters) {
      input.style.width = minWidth + 'px';
    } else {
      const extraCharacters = valueLength - maxCharacters;
      const newWidth = minWidth + extraCharacters * baseWidth;
      input.style.width = newWidth + 'px';
    }

    // Limiting the character count directly
    if (valueLength > maxCharacters) {
      input.value = input.value.slice(0, maxCharacters);
    }
  }

  // startCounterAnimation(){
  //   const counterValue = parseFloat(this.ourDocCount.toString());
  //   const counterElement = document.getElementById('counter');
    
  //   gsap.to(counterElement, {
  //     duration:2,
  //     innerHTML: counterValue,
  //     onUpdate: () => {

  //       if(counterElement){
  //         counterElement.innerText = Math.ceil(counterValue).toString();
  //       }
  //     }
  //   });
  // }
}
