import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CountServiceService} from './../../services/count-service.service'
@Component({
  selector: 'app-counter-main-page',
  templateUrl: './counter-main-page.component.html',
  styleUrls: ['./counter-main-page.component.css']
})
export class CounterMainPageComponent implements OnInit {
  documentCount = 0;
  documentCountResponse = 0;
  type = 1; // Default page is Our Impact Page
  isOurButtonClicked = false; // Default page is Our Impact Page
  isImpactButtonClicked = true;
  constructor(private route: ActivatedRoute, private counterService: CountServiceService) {
    
   }

  ngOnInit(): void {
    let orgId = '';
    let profileId = '';
    this.route.queryParams.subscribe(params => {
      // Access query parameters here
      profileId = params['profileId']||''; // Replace 'parameterName' with your actual parameter name
      // console.log('Parameter profileId:', profileId);
    });
    this.route.paramMap.subscribe(params => {
      // Access query parameters here
      orgId = params.get('orgId')||''; // Replace 'parameterName' with your actual parameter name
      // console.log('Parameter Value:', orgId);
    });
    this.counterService.getDocumentCount(orgId, profileId).subscribe( (resp) => {
      // console.log(resp);
      if(resp && resp?.pageCount && resp?.pageCount > 0)
      this.documentCountResponse = resp?.pageCount;
     this.documentCount = this.documentCountResponse;
    })
    // this.documentCount = 250000; // <- Add values
  }

  setType(selectedType: string) {
    if(selectedType==='OUR_IMPACT') {
      this.isOurButtonClicked = true;
      this.isImpactButtonClicked = false;
      this.documentCount = 0;
      this.documentCount = this.documentCountResponse;
      this.type = 0;
    }
    if(selectedType==='IMPACT_CALC') {
      this.isOurButtonClicked = false;
      this.isImpactButtonClicked = true;
      this.type = 1;
    } else {
      this.type = 0;
    }
  }

  redirectToClose(url: string): void {
    window.location.href = url;
    window.close(); 
  }

}
