<div class="zsec-content-main-section">
    <div class="zsec-content-top-section">
        <div class="zsec-content-input-section">
            <div class="zsec-slider-wrapper">
                <div class="zsec-content-heading" *ngIf="type==0">ZorroSign's positive impact on the environment.</div>
                <div class="zsec-content-heading" *ngIf="type==1">Measure your impact by dragging the slider</div>
                <input [style.background]="getLinearGradient()" *ngIf="type==1" type="range" id="rangeSlider" min="0"
                    [max]="MAX_DOC_COUNT" [(ngModel)]="documentCount" class="range" (input)="handleSliderChange($event)" />
            </div>
            <div class="zsec-counter-wrapper">
                <input *ngIf="type==0" [readonly]="true" mask="separator.0" thousandSeparator="," [ngModel]="ourDocCount"  (input)="adjustWidth($event)" class="zsec-pages-input-wrapper" />
                <input *ngIf="type==1" [(ngModel)]="documentCount" mask="separator.0" maxlength="7" thousandSeparator="," [value]="docCountString"  (input)="handleSliderChange($event, true)" class="zsec-pages-input-wrapper" appNumericInput type="text" />
                <div class="sheet-of-paper-wrapper">Sheets of paper saved</div>
            </div>
        </div>
        <div *ngIf="type==0" class="zsec-content-cards-section">
            <div class="zsec-first-card-wrapper">
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-tree.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value" id="counter">{{ ourDocCount.toString()|
                            calculateEnvironmentalImpact:'treesSaved' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Number of trees saved</div>
                </div>
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-water.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ ourDocCount.toString() |
                            calculateEnvironmentalImpact:'waterSaved' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Water saved (Gallons)</div>
                </div>
            </div>
            <div class="zsec-second-card-wrapper">
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-co2.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ ourDocCount.toString() |
                            calculateEnvironmentalImpact:'co2Emission' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">CO<sub>2</sub> emission avoided (kg)</div>
                </div>
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-cost.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ ourDocCount.toString() |
                            calculateEnvironmentalImpact:'costSaving' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Cost savings (in $)</div>
                </div>
            </div>
        </div>
        <div *ngIf="type==1" class="zsec-content-cards-section">
            <div class="zsec-first-card-wrapper">
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-tree.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value" id="counter">{{ documentCount.toString()|
                            calculateEnvironmentalImpact:'treesSaved' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Number of trees saved</div>
                </div>
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-water.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ documentCount.toString() |
                            calculateEnvironmentalImpact:'waterSaved' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Water Saved (Gallons)</div>
                </div>
            </div>
            <div class="zsec-second-card-wrapper">
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-co2.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ documentCount.toString() |
                            calculateEnvironmentalImpact:'co2Emission' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">CO<sub>2</sub> emission avoided (kg)</div>
                </div>
                <div class="zsec-content-card-wrapper">
                    <div class="zsec-content-card-top-wrapper">
                        <img src="../../../assets/card-cost.svg" class="zsec-content-card-top-wrapper-img" alt="">
                        <div class="zsec-content-card-value">{{ documentCount.toString() |
                            calculateEnvironmentalImpact:'costSaving' }}</div>
                    </div>
                    <div class="zsec-content-cards-detail-wrapper">Cost Savings (in $)</div>
                </div>
            </div>
        </div>
    </div>
    <div class="zsec-content-animation-section">
        <div class="copyright-text-wrapper">
            <p class="copyrightText">© 2023 ZorroSign, Inc. All rights reserved.</p>
          </div>
        <img src="../../../assets/01. Factory - Back.svg" class="layer-static layer-static1" alt="">
        <img src="../../../assets/46. Grass - Front.svg" class="layer-static layer-static2" alt="">
        <img src="../../../assets/23. Tree 05 - Trunk.svg" class="layer-static layer-static3" alt="">
        <img src="../../../assets/41. Tree 09 - Trunk.svg" class="layer layer1" alt="">
        <img src="../../../assets/44. Tree 09 - Leaves 04.svg" data-speed="0.1" class="layer layer2" alt="">
        <img src="../../../assets/43. Tree 09 - Leaves 03.svg" data-speed="0.1" class="layer layer3" alt="">
        <img src="../../../assets/42. Tree 09 - Leaves 02.svg" data-speed="0.1" class="layer layer4" alt="">
        <img src="../../../assets/40. Tree 09 - Leaves 01.svg" data-speed="0.1" class="layer layer5" alt="">
        <img src="../../../assets/25. Tree 05 - Leaves 03.svg" data-speed="0.1" class="layer layer6" alt="">
        <img src="../../../assets/24. Tree 05 - Leaves 02.svg" data-speed="0.1" class="layer layer7" alt="">
        <img src="../../../assets/22. Tree 05 - Leaves 01.svg" data-speed="0.1" class="layer layer8" alt="">
        <img src="../../../assets/32. Tree 07 - Trunk.svg" class="layer layer9" alt="">
        <img src="../../../assets/36. Tree 08 - Trunk.svg" class="layer layer10" alt="">
        <img src="../../../assets/34. Tree 07 - Leaves 03.svg" class="layer layer11" alt="">
        <img src="../../../assets/39. Tree 08 - Leaves 04.svg" class="layer layer12" alt="">
        <img src="../../../assets/33. Tree 07 - Leaves 02.svg" class="layer layer13" alt="">
        <img src="../../../assets/38. Tree 08 - Leaves 03.svg" class="layer layer14" alt="">
        <img src="../../../assets/31. Tree 07 - Leaves 01.svg" class="layer layer15" alt="">
        <img src="../../../assets/37. Tree 08 - Leaves 02.svg" class="layer layer16" alt="">
        <img src="../../../assets/27. Tree 06 - Trunk.svg" class="layer layer17" alt="">
        <img src="../../../assets/35. Tree 08 - Leaves 01.svg" class="layer layer18" alt="">
        <img src="../../../assets/30. Tree 06 - Leaves 04.svg" class="layer layer19" alt="">
        <img src="../../../assets/29. Tree 06 - Leaves 03.svg" class="layer layer20" alt="">
        <img src="../../../assets/14. Tree 03 - Trunk.svg" class="layer layer21" alt="">
        <img src="../../../assets/28. Tree 06 - Leaves 02.svg" class="layer layer22" alt="">
        <img src="../../../assets/17. Tree 03 - Leaves 04.svg" class="layer layer23" alt="">
        <img src="../../../assets/26. Tree 06 - Leaves 01.svg" class="layer layer24" alt="">
        <img src="../../../assets/16. Tree 03 - Leaves 03.svg" class="layer layer25" alt="">
        <img src="../../../assets/19. Tree 04 - Trunk.svg" class="layer layer26" alt="">
        <img src="../../../assets/15. Tree 03 - Leaves 02.svg" class="layer layer27" alt="">
        <img src="../../../assets/21. Tree 04 - Leaves 03.svg" class="layer layer28" alt="">
        <img src="../../../assets/13. Tree 03 - Leaves 01.svg" class="layer layer29" alt="">
        <img src="../../../assets/20. Tree 04 - Leaves 02.svg" class="layer layer30" alt="">
        <img src="../../../assets/04. Tree 01 - Trunk.svg" class="layer layer31" alt="">
        <img src="../../../assets/18. Tree 04 - Leaves 01.svg" class="layer layer32" alt="">
        <img src="../../../assets/09. Tree 02 - Trunk.svg" class="layer layer33" alt="">
        <img src="../../../assets/07. Tree 01 - Leaves 04.svg" class="layer layer34" alt="">
        <img src="../../../assets/12. Tree 02 - Leaves 04.svg" class="layer layer35" alt="">
        <img src="../../../assets/06. Tree 01 - Leaves 03.svg" class="layer layer36" alt="">
        <img src="../../../assets/11. Tree 02 - Leaves 03.svg" class="layer layer37" alt="">
        <img src="../../../assets/05. Tree 01 - Leaves 02.svg" class="layer layer38" alt="">
        <img src="../../../assets/10. Tree 02 - Leaves 02.svg" class="layer layer39" alt="">
        <img src="../../../assets/03. Tree 01 - Leaves 01.svg" class="layer layer40" alt="">
        <img src="../../../assets/08. Tree 02 - Leaves 01.svg" class="layer layer41" alt="">
        <img src="../../../assets/02. Forest - Back.svg" class="layer layer42" alt="">
        <img src="../../../assets/45. Shrubs - Front.svg" class="layer layer43" alt="">
    </div>
</div>