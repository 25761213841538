import { Component, OnInit } from '@angular/core';
import { CountServiceService } from './services/count-service.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'eco-counter-v2';
  posts:any;

  constructor() {
    // this.route.queryParams.subscribe(params => {
    //   // Access query parameters here
    //   const paramValue = params['pageCount']; // Replace 'parameterName' with your actual parameter name
    //   console.log('Parameter Value:', paramValue);
    // });
   }

  ngOnInit() {
    
}
}
