import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AnimateComponent } from './modules/animate/animate.component';
import { CounterMainPageComponent } from './modules/counter-main-page/counter-main-page.component';
import { HttpClientModule } from '@angular/common/http';
// import { RouterModule } from '@angular/router';
import { CalculateEnvironmentalImpactPipe } from './pipes/calculate-environmental-impact.pipe';

import { AppRoutingModule } from './app-routing.module';
import { NumericInputDirective } from './directive/numeric.directive';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [
    AppComponent,
    AnimateComponent,
    CounterMainPageComponent,
    CalculateEnvironmentalImpactPipe,
    NumericInputDirective,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxMaskModule.forRoot()
    // RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
