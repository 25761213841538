<div class="eco-calculator-main-wrapper">
    <div class="counter-top-wrapper">
        <button class="eco-counter-button" (click)="setType('IMPACT_CALC')" [ngClass]="{'clicked': isImpactButtonClicked}">Impact Calculator</button>
        <button class="eco-counter-button" (click)="setType('OUR_IMPACT')" [ngClass]="{'clicked': isOurButtonClicked}">Our Impact</button>
    </div>
    <ng-container *ngIf="documentCount !== 0 && documentCount !== undefined">
        <div class="eco-counter-content-wrapper">
            <app-animate [documentCount]="documentCount" [type]="type"></app-animate>
        </div>
    </ng-container>
    
</div>
